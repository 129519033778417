<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="CHANGE_EMAIL"
      tooltip-title="CHANGE_EMAIL"
      :display-breadcrumb="true"
    />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <InputBox
        type="text"
        name="Email"
        title="Email"
        label="Email"
        placeholder="jessiarose"
        class="md:w-64 mt-4 w-full"
      />
      <button
        class="mt-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
      >
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'

export default {
  components: {
    TitleWrapper,
    InputBox,
  },
}
</script>
